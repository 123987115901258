import {
	Stack,
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Divider,
	SimpleGrid,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Link,
	Input,
	IconButton,
	Tooltip,
	Radio,
	FormControl,
	Select,
	Text,
	Button,
	Flex,
	useToast,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalCloseButton,
	Icon,
	useColorModeValue,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Center,
	Card,
	Tabs,
	TabList,
	Tab,
	CardHeader,
	Heading,
	Circle,
	CardBody,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	FormLabel,
	RadioGroup,
	ButtonGroup,
	VStack,
	Skeleton,
} from "@chakra-ui/react";
import { GrScorecard } from "react-icons/gr";
import ApiService from "../../../service/Api.service";
import { GiTrophy } from "react-icons/gi";
import { FiSend } from "react-icons/fi";
import { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { BoardScoresTable } from "../../Events/BoardScores/BoardScoresTable";
import { CalendarWhite } from "../../../icons/Icons";
import { useAuthProvider } from "../../../context/AuthProvider";

export const TeamMatchupsTable = ({
	teamMatchupInitData,
	teamMatchupData,
	setTeamMatchupInitData,
	setTeamMatchupData,
	teamMatchSet,
	eventId,
	selctdTeamMatchGroup,
	selctdTeamMatchRound,
	refreshTeamMatchups,
	query,
	teamMatchupLoad,
	fetchFlags,
}) => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile BreakPoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});
	//Card Subtitle
	const cardSubtitle = useBreakpointValue({
		base: "lg",
		md: "xl",
	});
	// Round Info Text
	const roundText = useBreakpointValue({
		base: "md",
		md: "lg",
	});
	//Player Names
	const playerNames = useBreakpointValue({
		base: "md",
		sm: "lg",
		md: "2xl",
	});
	const playerScores = useBreakpointValue({
		base: "1.6rem",
		md: "2rem",
	});

	let updatedScoreData = [];
	let toast = useToast();

	// Loading States
	const [saveLoading, setSaveLoading] = useState(false);
	const [sendLoading, setSendLoading] = useState(null);
	const [saveKOLoading, setSaveKOLoading] = useState(false);
	const [tieLoading, setTieLoading] = useState(false);
	const [roundLoading, setRoundLoading] = useState(false);
	const [bsLoading, setBSLoading] = useState(null);
	const [boardLoading, setBoardLoading] = useState(false);

	const { isOpen, onOpen, onClose } = useDisclosure();
	// BoardScores Modal
	const { isOpen: isBoardOpen, onOpen: onBoardOpen, onClose: onBoardClose } = useDisclosure();

	// BoardScores States
	const [currMatchupId, setCurrMatchupId] = useState("");
	const [setCount, setSetCount] = useState(1);
	let [selectedSet, setSelectedSet] = useState(1);
	let [boardDetails, setBoardDetails] = useState(null);
	let [playersData, setPlayersData] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [player1Score, setPlayer1Score] = useState(null);
	const [player2Score, setPlayer2Score] = useState(null);
	const [strikeSlider, setStrikeSlider] = useState(0);
	const [player1Dir, setPlayer1Dir] = useState("");
	const [player2Dir, setPlayer2Dir] = useState("");
	const inputFocus = useRef(null);
	const [tabIndex, setTabIndex] = useState(0);
	const [koSetFlags, setKOSetFlags] = useState({});

	const updateMatchupsData = (propertyName, value, teamName, matchupId) => {
		if (!isNaN(value)) {
			setTeamMatchupData((prevData) => {
				const updatedData = prevData.map((team) => {
					if (team.TeamNames === teamName) {
						return {
							...team,
							Matchups: team.Matchups.map((matchup) =>
								matchup.MatchupId === matchupId
									? { ...matchup, [propertyName]: value }
									: matchup
							),
						};
					}
					return team;
				});
				return updatedData;
			});
		}
	};

	const saveScores = async (e) => {
		try {
			setSaveLoading(true);
			const initMatchups = _.flatMap(teamMatchupInitData, "Matchups");
			const updatedMatchups = _.flatMap(teamMatchupData, "Matchups");
			const differences = _.differenceWith(updatedMatchups, initMatchups, _.isEqual);

			let saveDataObj = {
				Scores: differences.map(({ MatchupId, Team1Score, Team2Score }) => ({
					MatchupId,
					Team1Score,
					Team2Score,
				})),
			};
			let updatedMatchUpsScoreResponse = await ApiService.httpPut(
				`/TourEventMatchupsByGroup?IsBulk=Yes`,
				saveDataObj
			);
			console.log("Saved Scores: ", updatedMatchUpsScoreResponse);
			updatedScoreData = [];
			if (updatedMatchUpsScoreResponse.status === true) {
				toast({
					title: "Save Scores",
					description: "Scores Saved Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				refreshTeamMatchups();
			} else if (updatedMatchUpsScoreResponse.status === false) {
				toast({
					title: "Save Scores",
					description: updatedMatchUpsScoreResponse.Message,
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Save Scores",
					description: "Failed to Save Scores.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSaveLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Scores!",
				description: "Failed to Save Scores.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setSaveLoading(false);
		}
	};

	const roundComplete = async () => {
		let reqObj = {
			TourEventId: eventId,
			GroupName: selctdTeamMatchGroup?.GroupId,
			RoundNo: selctdTeamMatchRound?.RoundNo,
		};
		try {
			setRoundLoading(true);
			let response = await ApiService.httpPost(
				"/TeamsRoundPlayers?Class=RoundComplete",
				reqObj
			);
			if (response.status === true) {
				onClose();
				toast({
					title: "Round Complete",
					description: response.Message,
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				fetchFlags();
				refreshTeamMatchups();
			} else if (response.status === false) {
				onClose();
				toast({
					title: "Round Complete",
					description: response.Message,
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onClose();
				toast({
					title: "Round Complete",
					description: "Failed to Complete Round.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setRoundLoading(false);
		} catch (err) {
			console.log(err);
			onClose();
			toast({
				title: "Round Complete",
				description: "Failed to Complete Round.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setRoundLoading(false);
		}
	};

	const tieBreak = async (team1, team2) => {
		let reqObj = {
			TourEventId: eventId,
			RoundNo: selctdTeamMatchRound?.RoundNo,
			Team1Id: team1,
			Team2Id: team2,
		};
		try {
			setTieLoading(true);
			let response = await ApiService.httpPost("/TeamEventTeams?Class=Tie", reqObj);
			if (response.status === true) {
				toast({
					title: "Tie Break",
					description: "Shoot Out Round Generated Successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				refreshTeamMatchups();
			} else if (response.status === false) {
				toast({
					title: "Tie Break",
					description: response.Message,
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Tie Break",
					description: "Failed to generate Shoot Out Round",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setTieLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Tie Break",
				description: "Failed to generate Shoot Out Round",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setTieLoading(false);
		}
	};
	const handleStatusUpdate = async (e, matchup) => {
		try {
			let isCompleted = e.target.value;
			let updatedStatusRes = await ApiService.httpPut(
				`/TourEventScores?MatchupId=${matchup.MatchupId}&isCompleted=${isCompleted}`
			);
			if (updatedStatusRes.status === true) {
				console.log("Matchups Updated Status:", updatedStatusRes);
				toast({
					title: "Update KO Matchup Status",
					description: "KO Matchup Status Updated Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				refreshTeamMatchups();
			} else if (updatedStatusRes.isTie === true) {
				console.log("Matchups Updated Status:", updatedStatusRes);
				toast({
					title: "Update KO Matchup Status",
					description: updatedStatusRes.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				console.log("Matchups Updated Status:", updatedStatusRes);
				toast({
					title: "Update KO Matchup Status",
					description: "Failed to Update KO Matchup Status",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Update KO Matchup Status",
				description: "Failed to Update KO Matchup Status",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};
	const handleKOStatus = async (propertyName, value, roundName, matchupId) => {
		try {
			let updatedStatusRes = await ApiService.httpPut(
				`/TourEventMatchupsByGroup?Class=KObyMatchupId&MatchupId=${matchupId}&IsComplete=${value}`
			);
			if (updatedStatusRes.status === true) {
				console.log("Matchups Updated Status:", updatedStatusRes);
				toast({
					title: "Update KO Matchup Status",
					description: updatedStatusRes.Message,
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setTeamMatchupData((prevData) => {
					const updatedData = prevData.map((round) => {
						if (round.RoundName === roundName) {
							return {
								...round,
								Matchups: round.Matchups.map((matchup) =>
									matchup.MatchupId === matchupId
										? { ...matchup, [propertyName]: value }
										: matchup
								),
							};
						}
						return round;
					});
					return updatedData;
				});
				setTeamMatchupInitData((prevData) => {
					const updatedData = prevData.map((round) => {
						if (round.RoundName === roundName) {
							return {
								...round,
								Matchups: round.Matchups.map((matchup) =>
									matchup.MatchupId === matchupId
										? { ...matchup, [propertyName]: value }
										: matchup
								),
							};
						}
						return round;
					});
					return updatedData;
				});
				refreshTeamMatchups();
			} else if (updatedStatusRes.isTie === true) {
				console.log("Matchups KO Updated Status:", updatedStatusRes);
				toast({
					title: "Update Matchup Status",
					description: updatedStatusRes.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else if (updatedStatusRes.status === false) {
				toast({
					title: "Update KO Matchup Status",
					description: updatedStatusRes.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				console.log("Matchups KO Updated Status:", updatedStatusRes);
				toast({
					title: "Update KO Matchup Status",
					description: "Failed to Update KO Matchup Status",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Updatem KO Matchup Status",
				description: "Failed to Updatem KO Matchup Status",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	// KO Team Matchups
	const sendScoreLink = async (matchupId) => {
		try {
			setSendLoading(matchupId);
			let saveScore = await ApiService.httpPost(
				`/ScoreLink?EventId=${eventId}&MatchupId=${matchupId}`
			);
			if (saveScore.status === true) {
				console.log("Score Link Sent", saveScore);
				toast({
					title: "Send Score Link",
					description: "Score Link Sent Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Send Score Link",
					description: "Failed to Send Score Link",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSendLoading(null);
		} catch (err) {
			console.log(err);
			toast({
				title: "Send Score Link",
				description: "Failed to Send Score Link",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setSendLoading(null);
		}
	};
	const checkBoardNumberChanged = () => {
		const isBoardNumberChanged = teamMatchupData.some((team) =>
			team.Matchups.some(
				(matchup) =>
					matchup.Board !==
					teamMatchupInitData
						.find((t) => t.TeamNames === team.TeamNames)
						.Matchups.find((m) => m.MatchupId === matchup.MatchupId).Board
			)
		);

		return isBoardNumberChanged;
	};
	const checkScoresChanged = () => {
		const isScoresChanged =
			teamMatchupData.some((team) =>
				team.Matchups.some(
					(matchup) =>
						matchup.Team1Set1 !==
						teamMatchupInitData
							.find((t) => t.TeamNames === team.TeamNames)
							.Matchups.find((m) => m.MatchupId === matchup.MatchupId).Team1Set1
				)
			) ||
			teamMatchupData.some((team) =>
				team.Matchups.some(
					(matchup) =>
						matchup.Team1Set2 !==
						teamMatchupInitData
							.find((t) => t.TeamNames === team.TeamNames)
							.Matchups.find((m) => m.MatchupId === matchup.MatchupId).Team1Set2
				)
			) ||
			teamMatchupData.some((team) =>
				team.Matchups.some(
					(matchup) =>
						matchup.Team1Set3 !==
						teamMatchupInitData
							.find((t) => t.TeamNames === team.TeamNames)
							.Matchups.find((m) => m.MatchupId === matchup.MatchupId).Team1Set3
				)
			) ||
			teamMatchupData.some((team) =>
				team.Matchups.some(
					(matchup) =>
						matchup.Team2Set1 !==
						teamMatchupInitData
							.find((t) => t.TeamNames === team.TeamNames)
							.Matchups.find((m) => m.MatchupId === matchup.MatchupId).Team2Set1
				)
			) ||
			teamMatchupData.some((team) =>
				team.Matchups.some(
					(matchup) =>
						matchup.Team2Set2 !==
						teamMatchupInitData
							.find((t) => t.TeamNames === team.TeamNames)
							.Matchups.find((m) => m.MatchupId === matchup.MatchupId).Team2Set2
				)
			) ||
			teamMatchupData.some((team) =>
				team.Matchups.some(
					(matchup) =>
						matchup.Team2Set3 !==
						teamMatchupInitData
							.find((t) => t.TeamNames === team.TeamNames)
							.Matchups.find((m) => m.MatchupId === matchup.MatchupId).Team2Set3
				)
			);

		return isScoresChanged;
	};
	const updateKOMatchupsData = (propertyName, value, teamName, matchupId) => {
		if (!isNaN(value)) {
			setTeamMatchupData((prevData) => {
				const updatedData = prevData.map((team) => {
					if (team.TeamNames === teamName) {
						return {
							...team,
							Matchups: team.Matchups.map((matchup) =>
								matchup.MatchupId === matchupId
									? { ...matchup, [propertyName]: value }
									: matchup
							),
						};
					}
					return team;
				});
				return updatedData;
			});
		}
	};
	const updateBoard = async (matchup) => {
		try {
			let reqObj = {
				boards: matchup.Matchups.map((item) => ({
					MatchupId: item.MatchupId,
					Board: item.Board,
				})),
			};
			console.log(reqObj);
			let updateBoardRes = await ApiService.httpPut(
				`/TourEventMatchupsByGroup?BulkBoards=Yes`,
				reqObj
			);
			console.log("Updated Board:", updateBoardRes);
			if (updateBoardRes.status === true) {
				toast({
					title: "Update Board(s)",
					description: "Board(s) Updated Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				refreshTeamMatchups();
			} else {
				toast({
					title: "Update Board(s)",
					description: "Failed to Update Board(s)",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Update Board(s)",
				description: "Failed to Update Board(s)",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};
	const saveKnockoutMD = async (matchup) => {
		try {
			setSaveKOLoading(true);
			let reqObj = {
				scores: matchup.Matchups.filter((updatedObj) => {
					const correspondingInitialObj = teamMatchupInitData
						.find((round) => round.TeamNames === matchup.TeamNames)
						.Matchups.find(
							(initialObj) => initialObj.MatchupId === updatedObj.MatchupId
						);
					return (
						correspondingInitialObj && !_.isEqual(correspondingInitialObj, updatedObj)
					);
				}).map((item) => ({
					MatchupId: item.MatchupId,
					Team1Set1: item.Team1Set1,
					Team1Set2: item.Team1Set2,
					Team1Set3: item.Team1Set3,
					Team2Set1: item.Team2Set1,
					Team2Set2: item.Team2Set2,
					Team2Set3: item.Team2Set3,
				})),
			};
			console.log(reqObj);
			let saveKnockoutMDRes = await ApiService.httpPut(
				`/TourEventScores?Class=KoBulkUpdate`,
				reqObj
			);
			console.log("Saved Knockout Matchup Data:", saveKnockoutMDRes);
			if (saveKnockoutMDRes.status === true) {
				toast({
					title: "Save Teams Matchup Score(s)",
					description: "Score(s) Updated Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				refreshTeamMatchups();
			} else {
				toast({
					title: "Save Teams Matchup Score(s)",
					description: "Failed to Update Score(s)",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSaveKOLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Teams Matchup Score(s)",
				description: "Failed to Update Score(s)",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setSaveKOLoading(false);
		}
	};

	// Board Scores modal
	const handleTabsChange = (index) => {
		setTabIndex(index);
		setSelectedSet(index + 1);
		console.log("Tab Index", index + 1);
	};
	const fetchBoardScores = async (matchupId, setIndex) => {
		try {
			setBSLoading(matchupId);
			setBoardLoading(true);
			let url = `/TourEventScores?MatchupId=${matchupId}`;
			let boardScoresRes = await ApiService.httpGet(url, user.userId);
			console.log("Score boards data:", boardScoresRes);
			if (setIndex === -1 && "LatestKnockoutSet" in boardScoresRes) {
				setIndex = boardScoresRes.LatestKnockoutSet;
				setTabIndex(boardScoresRes.LatestKnockoutSet - 1);
				setSelectedSet(boardScoresRes.LatestKnockoutSet);
			} else if (setIndex === -1) {
				setIndex = 1;
			}
			setSetCount(boardScoresRes.SetCount);
			setKOSetFlags({
				isSet1Accessible: boardScoresRes.isSet1Accessible,
				isSet2Accessible: boardScoresRes.isSet2Accessible,
				isSet3Accessible: boardScoresRes.isSet3Accessible,
			});
			setBoardDetails(boardScoresRes.ScoreDetails);
			setPlayer1Score(boardScoresRes.ScoreDetails[setIndex - 1].Player1TotalScore);
			setPlayer2Score(boardScoresRes.ScoreDetails[0].Player2TotalScore);
			setPlayersData([
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player1Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player1Name,
				},
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player2Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player2Name,
				},
			]);
			setStrikeSlider(
				boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike !== null
					? boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
					  boardScoresRes.ScoreDetails[setIndex - 1]?.Player1Id
						? -1
						: boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
						  boardScoresRes.ScoreDetails[setIndex - 1]?.Player2Id
						? 1
						: 0
					: 0
			);
			if (
				!(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === false &&
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === false
				)
			) {
				setPlayer1Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === true ? "L" : "R"
				);
				setPlayer2Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === true ? "L" : "R"
				);
			} else {
				setPlayer1Dir("");
				setPlayer2Dir("");
			}
			onBoardOpen();
			setBSLoading(null);
			setBoardLoading(false);
		} catch (err) {
			console.log(err);
			setBSLoading(null);
			setBoardLoading(false);
		}
	};

	function refreshOnScoresSave(matchupId, setIndex, boardScoresRes) {
		try {
			setBSLoading(matchupId);
			setBoardLoading(true);
			if (setIndex === -1 && "LatestKnockoutSet" in boardScoresRes) {
				setIndex = boardScoresRes.LatestKnockoutSet;
				setTabIndex(boardScoresRes.LatestKnockoutSet - 1);
				setSelectedSet(boardScoresRes.LatestKnockoutSet);
			} else if (setIndex === -1) {
				setIndex = 1;
			}
			setSetCount(boardScoresRes.SetCount);
			setKOSetFlags({
				isSet1Accessible: boardScoresRes.isSet1Accessible,
				isSet2Accessible: boardScoresRes.isSet2Accessible,
				isSet3Accessible: boardScoresRes.isSet3Accessible,
			});
			setBoardDetails(boardScoresRes.ScoreDetails);
			setPlayer1Score(boardScoresRes.ScoreDetails[setIndex - 1].Player1TotalScore);
			setPlayer2Score(boardScoresRes.ScoreDetails[0].Player2TotalScore);
			setPlayersData([
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player1Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player1Name,
				},
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player2Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player2Name,
				},
			]);
			setStrikeSlider(
				boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike !== null
					? boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
					  boardScoresRes.ScoreDetails[setIndex - 1]?.Player1Id
						? -1
						: boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
						  boardScoresRes.ScoreDetails[setIndex - 1]?.Player2Id
						? 1
						: 0
					: 0
			);
			if (
				!(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === false &&
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === false
				)
			) {
				setPlayer1Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === true ? "L" : "R"
				);
				setPlayer2Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === true ? "L" : "R"
				);
			} else {
				setPlayer1Dir("");
				setPlayer2Dir("");
			}
			onBoardOpen();
			setBSLoading(null);
			setBoardLoading(false);
		} catch (err) {
			console.log(err);
			setBSLoading(null);
			setBoardLoading(false);
		}
	}

	const saveBoardScores = async () => {
		try {
			let reqObj = {
				MatchupId: currMatchupId,
				Team1Score: player1Score,
				Team2Score: player2Score,
			};
			if (setCount > 1) {
				reqObj = {
					MatchupId: currMatchupId,
					Team1Score: player1Score,
					Team2Score: player2Score,
					KnockoutSet: selectedSet,
				};
			}
			let url = `/TourEventMatchupsByGroup?MatchupId=${currMatchupId}`;
			let saveScores = await ApiService.httpPut(url, reqObj);
			if (saveScores.status === true) {
				toast({
					title: "Save Board Scores",
					description: "Board Scores Saved Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				console.log("Saved Scores: ", saveScores);
				fetchBoardScores(currMatchupId, selectedSet);
				refreshTeamMatchups();
			} else {
				toast({
					title: "Save Board Scores",
					description: saveScores.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Board Scores",
				description: "Failed to Save Board Scores.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const saveFirstStrike = async (value) => {
		setStrikeSlider(value);
		if (value !== 0) {
			try {
				let reqObj = {
					MatchupId: currMatchupId,
					FirstStrike:
						value === -1
							? boardDetails[tabIndex]?.Player1Id
							: boardDetails[tabIndex]?.Player2Id,
				};
				let url = "/TourEventScores?Class=UpdateFirstStrike";
				if (setCount > 1) {
					url = `/TourEventScores?Class=UpdateFirstStrike&KnockoutSet=${selectedSet}`;
				}
				let strikeRes = await ApiService.httpPut(url, reqObj);
				if (strikeRes.status === true) {
					toast({
						title: "Save First Strike",
						description: "First Strike Saved Successfully.",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					fetchBoardScores(currMatchupId, selectedSet);
				} else {
					toast({
						title: "Save First Strike",
						description: "Failed to Save First Strike.",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				console.log(reqObj);
			} catch (err) {
				console.log(err);
				toast({
					title: "Save First Strike",
					description: "Failed to Save First Strike.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		}
	};

	const updatePlayerPosition = async (position, value) => {
		let reqObj;
		if (position === "Player1" && value !== "") {
			if (position === "Player1" && value === "L") {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player1Id,
				};
			} else {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player2Id,
				};
			}
		} else if (position === "Player2" && value !== "") {
			if (position === "Player2" && value === "L") {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player2Id,
				};
			} else {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player1Id,
				};
			}
		}
		try {
			let url = `/TourEventScores?Class=UpdateSittingLeft`;
			if (setCount > 1) {
				url = `/TourEventScores?Class=UpdateSittingLeft&KnockoutSet=${selectedSet}`;
			}
			let res = await ApiService.httpPut(url, reqObj);
			if (res.status === true) {
				toast({
					title: "Update Player Seating Position",
					description: "Player Seating Position Updated Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				fetchBoardScores(currMatchupId, selectedSet);
			} else {
				toast({
					title: "Update Player Seating Position",
					description: "Failed to Update Player Seating Position.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Update Player Seating Position",
				description: "Failed to Update Player Seating Position.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	useEffect(() => {
		editMode && inputFocus.current.focus();
	}, [editMode]);

	let navigateToScoreBord = (e, matchup) => {
		// navigate('/boardscores', {state: {matchupId: matchup.MatchupId}})
		window.open(`/boardscores?matchupId=${matchup.MatchupId}`, "_board");
	};

	return (
		<>
			{teamMatchupLoad ? (
				<Stack>
					<Skeleton h={"4vh"} isLoaded={!teamMatchupLoad} />
					<Skeleton h={"4vh"} isLoaded={!teamMatchupLoad} />
					<Skeleton h={"4vh"} isLoaded={!teamMatchupLoad} />
				</Stack>
			) : (
				<Box>
					<Accordion allowToggle>
						{teamMatchupData?.map((team, index1) => {
							return (
								<AccordionItem key={team.TeamNames}>
									<h2>
										<AccordionButton _expanded={{ textColor: "accent" }}>
											<Box as="span" flex="1" textAlign="left">
												{team.TeamNames}
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel px="1">
										{teamMatchSet > 1 ? (
											<SimpleGrid
												columns={{ base: "1", xl: "2" }}
												spacingX="1rem">
												{team?.Matchups.map((board, index) => (
													<Box
														key={board.MatchupId}
														borderRadius={"2xl"}
														mb="1rem"
														overflow="auto"
														boxShadow={"sm"}
														css={{
															"::-webkit-scrollbar": {
																width: "0.3rem",
																height: "0.3rem",
															},
															"::-webkit-scrollbar-track": {
																background: "#bee3f8",
																borderRadius: "1rem",
															},
															"::-webkit-scrollbar-thumb": {
																background: "#3182ce",
																borderRadius: "1rem",
															},
														}}>
														<Text
															fontSize="lg"
															bgColor="black"
															borderTopRadius={"2xl"}
															textColor="white"
															fontWeight="medium"
															textAlign="center"
															pt={2}>
															{board.Position}
														</Text>
														<Table variant="striped">
															<Thead
																position="sticky"
																top={0}
																bgColor="black"
																zIndex="2">
																<Tr>
																	<Th
																		textColor="white"
																		fontSize="md"
																		textAlign="center"
																		px={2}
																		py={4}>
																		Board
																	</Th>
																	<Th
																		textColor="white"
																		fontSize="md"
																		textAlign="start">
																		Player Name
																	</Th>
																	<Th
																		textColor="white"
																		fontSize="md"
																		textAlign="center"
																		px={2}>
																		Set 1
																	</Th>
																	<Th
																		textColor="white"
																		fontSize="md"
																		textAlign="center"
																		px={2}>
																		Set 2
																	</Th>
																	<Th
																		textColor="white"
																		fontSize="md"
																		textAlign="center"
																		px={2}>
																		Set 3
																	</Th>
																</Tr>
															</Thead>
															<Tbody>
																<Tr>
																	<Td
																		textAlign="center"
																		fontSize="lg"
																		rowSpan={2}
																		fontWeight="medium"
																		px={{ base: 0, sm: 2 }}>
																		<SimpleGrid
																			columns={{
																				base: "1",
																				md: "2",
																			}}
																			spacingY={3}
																			spacingX={2}>
																			<Input
																				textAlign="center"
																				p={1}
																				value={board.Board}
																				name="Board"
																				onChange={(e) => {
																					updateKOMatchupsData(
																						e.target
																							.name,
																						Number(
																							e.target
																								.value
																						),
																						team.TeamNames,
																						board.MatchupId
																					);
																				}}
																			/>
																			<IconButton
																				color="green.500"
																				_hover={{
																					bgColor:
																						"green.100",
																				}}
																				icon={
																					<FiSend size="1.3rem" />
																				}
																				onClick={() =>
																					sendScoreLink(
																						board.MatchupId
																					)
																				}
																				isLoading={
																					sendLoading ===
																					board.MatchupId
																				}
																				variant="ghost"
																			/>
																			<FormControl>
																				<Select
																					disabled={
																						!_(
																							teamMatchupInitData
																						)
																							.differenceWith(
																								teamMatchupData,
																								_.isEqual
																							)
																							.isEmpty()
																					}
																					name="IsComplete"
																					value={
																						board.IsComplete
																					}
																					onChange={(e) =>
																						handleKOStatus(
																							e.target
																								.name,
																							e.target
																								.value,
																							team.RoundName,
																							board.MatchupId
																						)
																					}>
																					<option
																						value={0}>
																						In Progress
																					</option>
																					<option
																						value={1}>
																						Completed
																					</option>
																				</Select>
																			</FormControl>
																			<Tooltip
																				closeOnClick={true}
																				hasArrow
																				label="KO Board Scores"
																				aria-label="KO Board Scores Button">
																				<IconButton
																					color="#3182ce"
																					variant="ghost"
																					icon={
																						<GrScorecard size="1.3rem" />
																					}
																					isLoading={
																						bsLoading ===
																						board.MatchupId
																					}
																					onClick={(
																						e
																					) => {
																						setCurrMatchupId(
																							board.MatchupId
																						);
																						fetchBoardScores(
																							board.MatchupId,
																							-1
																						);
																					}}
																				/>
																			</Tooltip>
																		</SimpleGrid>
																	</Td>
																	<Td
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium"
																		px={{ base: 0, sm: 2 }}>
																		<HStack>
																			<Text
																				whiteSpace={
																					"normal"
																				}>
																				{board.Player1Name}
																			</Text>
																			{board.IsPlayer1won ===
																				1 && (
																				<Icon
																					as={GiTrophy}
																				/>
																			)}
																		</HStack>
																	</Td>
																	<Td
																		textAlign="start"
																		px={1}
																		fontSize="md"
																		fontWeight="medium">
																		<FormControl w="-webkit-fit-content">
																			<NumberInput
																				value={Number(
																					board.Team1Set1
																				)}
																				min={0}
																				max={25}
																				inputMode="numeric"
																				keepWithinRange={
																					true
																				}
																				onChange={(value) =>
																					updateKOMatchupsData(
																						"Team1Set1",
																						Number(
																							value
																						),
																						team.TeamNames,
																						board.MatchupId
																					)
																				}>
																				<NumberInputField
																					textAlign={
																						"center"
																					}
																					padding={0}
																				/>
																			</NumberInput>
																		</FormControl>
																	</Td>
																	<Td
																		textAlign="start"
																		px={1}
																		fontSize="md"
																		fontWeight="medium">
																		<FormControl w="-webkit-fit-content">
																			<NumberInput
																				value={Number(
																					board.Team1Set2
																				)}
																				min={0}
																				max={25}
																				inputMode="numeric"
																				keepWithinRange={
																					true
																				}
																				onChange={(value) =>
																					updateKOMatchupsData(
																						"Team1Set2",
																						Number(
																							value
																						),
																						team.TeamNames,
																						board.MatchupId
																					)
																				}>
																				<NumberInputField
																					textAlign={
																						"center"
																					}
																					padding={0}
																				/>
																			</NumberInput>
																		</FormControl>
																	</Td>
																	<Td
																		textAlign="start"
																		px={1}
																		fontSize="md"
																		fontWeight="medium">
																		<FormControl w="-webkit-fit-content">
																			<NumberInput
																				value={Number(
																					board.Team1Set3
																				)}
																				min={0}
																				max={25}
																				inputMode="numeric"
																				keepWithinRange={
																					true
																				}
																				onChange={(value) =>
																					updateKOMatchupsData(
																						"Team1Set3",
																						Number(
																							value
																						),
																						team.TeamNames,
																						board.MatchupId
																					)
																				}>
																				<NumberInputField
																					textAlign={
																						"center"
																					}
																					padding={0}
																				/>
																			</NumberInput>
																		</FormControl>
																	</Td>
																</Tr>
																<Tr key={board.id}>
																	<Td
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium"
																		px={{ base: 0, sm: 2 }}>
																		<HStack>
																			<Text
																				whiteSpace={
																					"normal"
																				}>
																				{board.Player2Name}
																			</Text>
																			{board.IsPlayer2won ===
																				1 && (
																				<Icon
																					as={GiTrophy}
																				/>
																			)}
																		</HStack>
																	</Td>
																	<Td
																		textAlign="start"
																		px={1}
																		fontSize="md"
																		fontWeight="medium">
																		<NumberInput
																			value={Number(
																				board.Team2Set1
																			)}
																			min={0}
																			max={25}
																			inputMode="numeric"
																			keepWithinRange={true}
																			onChange={(value) =>
																				updateKOMatchupsData(
																					"Team2Set1",
																					Number(value),
																					team.TeamNames,
																					board.MatchupId
																				)
																			}>
																			<NumberInputField
																				textAlign={"center"}
																				padding={0}
																			/>
																		</NumberInput>
																	</Td>
																	<Td
																		textAlign="start"
																		px={1}
																		fontSize="md"
																		fontWeight="medium">
																		<NumberInput
																			value={Number(
																				board.Team2Set2
																			)}
																			min={0}
																			max={25}
																			inputMode="numeric"
																			keepWithinRange={true}
																			onChange={(value) =>
																				updateKOMatchupsData(
																					"Team2Set2",
																					Number(value),
																					team.TeamNames,
																					board.MatchupId
																				)
																			}>
																			<NumberInputField
																				textAlign={"center"}
																				padding={0}
																			/>
																		</NumberInput>
																	</Td>
																	<Td
																		textAlign="start"
																		px={1}
																		fontSize="md"
																		fontWeight="medium">
																		<NumberInput
																			value={Number(
																				board.Team2Set3
																			)}
																			min={0}
																			max={25}
																			inputMode="numeric"
																			keepWithinRange={true}
																			onChange={(value) =>
																				updateKOMatchupsData(
																					"Team2Set3",
																					Number(value),
																					team.TeamNames,
																					board.MatchupId
																				)
																			}>
																			<NumberInputField
																				textAlign={"center"}
																				padding={0}
																			/>
																		</NumberInput>
																	</Td>
																</Tr>
															</Tbody>
														</Table>
													</Box>
												))}
											</SimpleGrid>
										) : (
											<Box
												borderRadius={"2xl"}
												overflow="auto"
												mb="1rem"
												boxShadow={"sm"}
												css={{
													"::-webkit-scrollbar": {
														width: "0.2rem",
														height: "0.2rem",
													},
													"::-webkit-scrollbar-track": {
														background: "#bee3f8",
														borderRadius: "1rem",
													},
													"::-webkit-scrollbar-thumb": {
														background: "#3182ce",
														borderRadius: "1rem",
													},
												}}>
												<Table variant="striped">
													<Thead
														position="sticky"
														top={0}
														bgColor="black"
														zIndex="2">
														<Tr>
															<Th
																flex="1"
																textColor="white"
																fontSize="md"
																textAlign="start"
																py={4}
																borderTopLeftRadius="2xl">
																Board
															</Th>
															<Th
																flex="1"
																textColor="white"
																fontSize="md"
																textAlign="start">
																Status
															</Th>
															<Th
																flex="1"
																textColor="white"
																fontSize="md"
																textAlign="start">
																Position
															</Th>
															<Th
																flex="1"
																textColor="white"
																fontSize="md"
																textAlign="start">
																Player (s)
															</Th>
															<Th
																flex="1"
																textColor="white"
																fontSize="md"
																textAlign="start">
																Score
															</Th>
															<Th
																flex="1"
																textColor="white"
																fontSize="md"
																textAlign="start">
																Player(s)
															</Th>
															<Th
																flex="1"
																textColor="white"
																fontSize="md"
																textAlign="start">
																Score
															</Th>
															<Th
																flex="1"
																textColor="white"
																fontSize="md"
																textAlign="start"
																borderTopRightRadius="2xl">
																Action
															</Th>
														</Tr>
													</Thead>
													<Tbody>
														{team?.Matchups?.filter((player) => {
															if (query === "") {
																return player;
															} else if (
																player?.Player1Name.toLowerCase().includes(
																	query.toLowerCase()
																) ||
																player?.Player2Name.toLowerCase().includes(
																	query.toLowerCase()
																)
															) {
																return player;
															}
														})?.map((match, index2) => {
															return (
																<Tr key={match.MatchupId}>
																	<Td
																		flex="1"
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium"
																		pr={2}>
																		{match.Board}
																	</Td>
																	<Td
																		flex="1"
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium">
																		<FormControl
																			textAlign="start"
																			width={
																				"-webkit-max-content"
																			}>
																			<Select
																				disabled={
																					!_(
																						teamMatchupInitData
																					)
																						.differenceWith(
																							teamMatchupData,
																							_.isEqual
																						)
																						.isEmpty()
																				}
																				defaultValue={
																					match.IsComplete
																				}
																				onChange={(e) =>
																					handleStatusUpdate(
																						e,
																						match
																					)
																				}>
																				<option value={0}>
																					In Progress
																				</option>
																				<option value={1}>
																					Completed
																				</option>
																			</Select>
																		</FormControl>
																	</Td>
																	<Td
																		flex="1"
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium">
																		{match.Position}
																	</Td>
																	<Td
																		flex="1"
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium">
																		<HStack justify="space-between">
																			<Text>
																				{match.Player1Name}
																			</Text>
																			{match.IsPlayer1won ===
																				1 && (
																				<Icon
																					as={GiTrophy}
																				/>
																			)}
																		</HStack>
																	</Td>
																	<Td
																		flex="1"
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium">
																		<FormControl>
																			<NumberInput
																				value={Number(
																					match.Team1Score
																				)}
																				min={0}
																				max={25}
																				inputMode="numeric"
																				keepWithinRange={
																					true
																				}
																				onChange={(value) =>
																					updateMatchupsData(
																						"Team1Score",
																						Number(
																							value
																						),
																						team.TeamNames,
																						match.MatchupId
																					)
																				}>
																				<NumberInputField
																					textAlign={
																						"center"
																					}
																					padding={0}
																				/>
																			</NumberInput>
																		</FormControl>
																	</Td>
																	<Td
																		flex="1"
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium">
																		<HStack justify="space-between">
																			<Text>
																				{match.Player2Name}
																			</Text>
																			{match.IsPlayer2won ===
																				1 && (
																				<Icon
																					as={GiTrophy}
																				/>
																			)}
																		</HStack>
																	</Td>
																	<Td
																		flex="1"
																		textAlign="start"
																		fontSize="lg"
																		fontWeight="medium">
																		<FormControl>
																			<NumberInput
																				value={Number(
																					match.Team2Score
																				)}
																				min={0}
																				max={25}
																				inputMode="numeric"
																				keepWithinRange={
																					true
																				}
																				onChange={(value) =>
																					updateMatchupsData(
																						"Team2Score",
																						Number(
																							value
																						),
																						team.TeamNames,
																						match.MatchupId
																					)
																				}>
																				<NumberInputField
																					textAlign={
																						"center"
																					}
																					padding={0}
																				/>
																			</NumberInput>
																		</FormControl>
																	</Td>
																	<Td
																		flex="1"
																		textAlign="start"
																		fontWeight="semibold">
																		<Tooltip
																			closeOnClick={true}
																			hasArrow
																			label="Board Scores"
																			aria-label="Board Scores Button">
																			<IconButton
																				color="#3182ce"
																				variant="ghost"
																				icon={
																					<GrScorecard size="1.3rem" />
																				}
																				isLoading={
																					bsLoading ===
																					match.MatchupId
																				}
																				onClick={() => {
																					setCurrMatchupId(
																						match.MatchupId
																					);
																					fetchBoardScores(
																						match.MatchupId,
																						-1
																					);
																				}}
																			/>
																		</Tooltip>
																	</Td>
																</Tr>
															);
														})}
													</Tbody>
												</Table>
											</Box>
										)}
										<Flex
											justify={teamMatchSet > 1 ? "space-between" : "start"}>
											<Button
												color="white"
												bgColor="bg-accent"
												borderRadius="full"
												isLoading={tieLoading}
												onClick={() =>
													tieBreak(team.Team1Id, team.Team2Id)
												}>
												Tie Break
											</Button>
											{teamMatchSet > 1 && (
												<Button
													color="white"
													bgColor="bg-accent"
													borderRadius="full"
													isLoading={saveKOLoading}
													onClick={() => {
														if (
															checkBoardNumberChanged() === true &&
															checkScoresChanged() == true
														) {
															updateBoard(team);
															saveKnockoutMD(team);
														} else if (
															checkBoardNumberChanged() === true
														) {
															updateBoard(team);
														} else if (checkScoresChanged() == true) {
															saveKnockoutMD(team);
														} else {
															console.log("No Changes Made");
														}
													}}
													disabled={_(teamMatchupInitData)
														.differenceWith(teamMatchupData, _.isEqual)
														.isEmpty()}>
													Save
												</Button>
											)}
										</Flex>
									</AccordionPanel>
								</AccordionItem>
							);
						})}
					</Accordion>

					<Divider />
					<HStack
						justify={teamMatchSet === 1 ? "space-between" : "end"}
						p="1rem"
						bgColor="white"
						borderBottomRadius={"2xl"}>
						{teamMatchSet === 1 && (
							<Button
								color="white"
								bgColor="bg-accent"
								borderRadius="full"
								onClick={saveScores}
								isLoading={saveLoading}
								disabled={_(teamMatchupInitData)
									.differenceWith(teamMatchupData, _.isEqual)
									.isEmpty()}>
								Save Scores
							</Button>
						)}
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							onClick={onOpen}
							isLoading={roundLoading}>
							Round Complete
						</Button>
					</HStack>
				</Box>
			)}
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Round Complete</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure want to Complete Round?</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="blue"
							mr={3}
							onClick={roundComplete}
							isLoading={roundLoading}>
							Yes
						</Button>
						{!roundLoading && <Button onClick={onClose}>No</Button>}
					</ModalFooter>
				</ModalContent>
			</Modal>
			{boardDetails && (
				<Modal
					isOpen={isBoardOpen}
					onClose={() => {
						onBoardClose();
						setCurrMatchupId("");
						setEditMode(false);
					}}
					closeOnOverlayClick={false}
					size="6xl"
					isCentered
					scrollBehavior="inside">
					<ModalOverlay />
					<ModalContent>
						<ModalHeader fontSize={"2xl"}>Board Scores</ModalHeader>
						<ModalBody
							css={{
								"::-webkit-scrollbar": {
									width: "0.3rem",
									height: "0.3rem",
								},
								"::-webkit-scrollbar-track": {
									background: "#bee3f8",
									borderRadius: "1rem",
								},
								"::-webkit-scrollbar-thumb": {
									background: "#3182ce",
									borderRadius: "1rem",
								},
							}}>
							<Stack spacing="2rem">
								<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
									<Tabs
										variant="line"
										isFitted
										size="lg"
										index={tabIndex}
										onChange={handleTabsChange}>
										{setCount > 1 && (
											<TabList>
												<Tab isDisabled={!koSetFlags.isSet1Accessible}>
													Set-1
												</Tab>
												<Tab isDisabled={!koSetFlags.isSet2Accessible}>
													Set-2
												</Tab>
												<Tab isDisabled={!koSetFlags.isSet3Accessible}>
													Set-3
												</Tab>
											</TabList>
										)}
										<CardHeader>
											<Stack spacing="1.2rem" divider={<Divider />}>
												<Stack
													direction={{
														base: "column",
														md: "row",
													}}
													{...(isMobile
														? { spacing: "1rem" }
														: { spacing: "1.5rem" })}
													align={{
														base: "start",
														md: "center",
													}}
													justify="space-between">
													<Stack
														direction={"row"}
														spacing="1.2rem"
														align="center">
														<Center
															bgColor={editMode ? "red" : "accent"}
															p="1rem"
															borderRadius="xl"
															onClick={() => {
																boardDetails[tabIndex]
																	.FirstStrike !== null &&
																	setEditMode(!editMode);
																(player1Score !==
																	boardDetails[tabIndex]
																		?.Player1TotalScore ||
																	player2Score !==
																		boardDetails[tabIndex]
																			?.Player2TotalScore) &&
																	editMode &&
																	saveBoardScores();
															}}>
															<Icon as={CalendarWhite} />
														</Center>
														<Stack
															justify={"space-between"}
															{...(isMobile && { w: "100%" })}
															spacing={0}>
															<Heading as="h2" size={cardHeading}>
																{
																	boardDetails[tabIndex]
																		?.TournamentName
																}
															</Heading>
															<Text
																fontSize={cardSubtitle}
																fontWeight="semibold"
																color="accent"
																as="h4">
																{boardDetails[tabIndex]?.EventName}
																{boardDetails[tabIndex]
																	?.GroupName &&
																	" - Group " +
																		boardDetails[tabIndex]
																			?.GroupName}
															</Text>
															<Text
																fontSize={roundText}
																color={"InactiveCaptionText"}
																fontWeight="semibold"
																as="h4">
																{boardDetails[tabIndex]
																	?.RoundName &&
																	boardDetails[tabIndex]
																		?.RoundName}
															</Text>
														</Stack>
													</Stack>
													<VStack
														align={"end"}
														spacing={1}
														alignSelf={"end"}>
														<Text
															fontSize={roundText}
															color={"InactiveCaptionText"}
															fontWeight={"medium"}>
															{boardDetails[tabIndex]?.Venue !==
																null &&
																"Venue: " +
																	boardDetails[tabIndex]?.Venue}
														</Text>
														<Text
															fontSize={roundText}
															color={"InactiveCaptionText"}
															fontWeight={"medium"}>
															{boardDetails[tabIndex]?.RefereeName !==
																null &&
																"Referee: " +
																	boardDetails[tabIndex]
																		?.RefereeName}
														</Text>
													</VStack>
												</Stack>
												<Stack direction={"row"} justify="space-between">
													<Stack direction={"column"}>
														<Stack
															direction="row"
															align={"center"}
															spacing=".7rem">
															<Text
																as="h3"
																fontSize={playerNames}
																boxSize={{
																	base: "-webkit-min-content",
																	sm: "-webkit-fit-content",
																}}
																fontWeight="semibold">
																{boardDetails[tabIndex].Player1Name}
															</Text>
															{editMode ? (
																<NumberInput
																	min={0}
																	max={25}
																	defaultValue={
																		boardDetails[tabIndex]
																			.Player1TotalScore
																	}
																	onChange={(value) => {
																		setPlayer1Score(
																			Number(value)
																		);
																	}}>
																	<NumberInputField
																		ref={inputFocus}
																		boxSize={{
																			base: "1.8rem",
																			md: "2.1rem",
																		}}
																		p={0}
																		textAlign={"center"}
																		fontWeight={"semibold"}
																		bg={"#3A95FF"}
																		borderRadius={"full"}
																		textColor={"white"}
																	/>
																</NumberInput>
															) : (
																<Circle
																	size={playerScores}
																	fontWeight="semibold"
																	textColor="white"
																	bgColor="#3A95FF">
																	{
																		boardDetails[tabIndex]
																			.Player1TotalScore
																	}
																</Circle>
															)}
														</Stack>
														<FormControl>
															<RadioGroup
																onChange={(value) => {
																	setPlayer1Dir(value);
																	value !== "" && value === "L"
																		? setPlayer2Dir("R")
																		: setPlayer2Dir("L");
																	updatePlayerPosition(
																		"Player1",
																		value
																	);
																}}
																value={player1Dir}>
																<Stack
																	direction={"row"}
																	spacing={isMobile ? 2 : 3}>
																	<Radio
																		colorScheme="red"
																		spacing={isMobile ? 1 : 2}
																		value="L">
																		{isMobile ? "L" : "Left"}
																	</Radio>
																	<Radio
																		colorScheme="green"
																		spacing={isMobile ? 1 : 2}
																		value="R">
																		{isMobile ? "R" : "Right"}
																	</Radio>
																</Stack>
															</RadioGroup>
														</FormControl>
													</Stack>
													<FormControl
														alignSelf={"center"}
														w="-webkit-fit-content">
														<FormLabel textAlign={"center"}>
															First Strike
														</FormLabel>
														<Slider
															value={strikeSlider}
															min={-1}
															max={1}
															step={1}
															onChange={(value) => {
																saveFirstStrike(value);
															}}>
															<SliderTrack
																bg={
																	strikeSlider === -1
																		? "#3A95FF"
																		: strikeSlider === 1
																		? "#800080"
																		: "gray"
																}
																h={"1rem"}
																borderRadius={"full"}>
																<Box
																	position="relative"
																	right={10}
																/>
																<SliderFilledTrack bg="" />
															</SliderTrack>
															<SliderThumb boxSize={6} />
														</Slider>
													</FormControl>
													<Stack direction={"column"}>
														<Stack
															direction="row"
															align={"center"}
															spacing=".7rem">
															<Text
																as="h3"
																fontSize={playerNames}
																boxSize={{
																	base: "-webkit-min-content",
																	sm: "-webkit-fit-content",
																}}
																fontWeight="semibold">
																{boardDetails[tabIndex].Player2Name}
															</Text>
															{editMode ? (
																<NumberInput
																	min={0}
																	max={25}
																	defaultValue={
																		boardDetails[tabIndex]
																			.Player2TotalScore
																	}
																	onChange={(value) => {
																		setPlayer2Score(
																			Number(value)
																		);
																	}}>
																	<NumberInputField
																		boxSize={{
																			base: "1.8rem",
																			md: "2.1rem",
																		}}
																		p={0}
																		textAlign={"center"}
																		fontWeight={"semibold"}
																		bg={"#800080"}
																		borderRadius={"full"}
																		textColor={"white"}
																	/>
																</NumberInput>
															) : (
																<Circle
																	size={playerScores}
																	fontWeight="semibold"
																	textColor="white"
																	bgColor="#800080">
																	{
																		boardDetails[tabIndex]
																			.Player2TotalScore
																	}
																</Circle>
															)}
														</Stack>
														<FormControl>
															<RadioGroup
																onChange={(value) => {
																	setPlayer2Dir(value);
																	value !== "" && value === "L"
																		? setPlayer1Dir("R")
																		: setPlayer1Dir("L");
																	updatePlayerPosition(
																		"Player2",
																		value
																	);
																}}
																value={player2Dir}>
																<Stack
																	direction={"row"}
																	spacing={isMobile ? 2 : 3}>
																	<Radio
																		colorScheme="red"
																		spacing={isMobile ? 1 : 2}
																		value="L">
																		{isMobile ? "L" : "Left"}
																	</Radio>
																	<Radio
																		colorScheme="green"
																		spacing={isMobile ? 1 : 2}
																		value="R">
																		{isMobile ? "R" : "Right"}
																	</Radio>
																</Stack>
															</RadioGroup>
														</FormControl>
													</Stack>
												</Stack>
											</Stack>
										</CardHeader>
										<CardBody p={0}>
											<BoardScoresTable
												matchupId={currMatchupId}
												scoresData={boardDetails[tabIndex].Scores}
												boardsData={boardDetails[tabIndex]}
												playersData={playersData}
												refreshView={fetchBoardScores}
												refreshMatchups={refreshTeamMatchups}
												selectedSet={selectedSet}
												setCount={setCount}
												setLoading={setBoardLoading}
												loading={boardLoading}
												setBSLoading={setBSLoading}
												refreshOnScoresSave={refreshOnScoresSave}
											/>
										</CardBody>
									</Tabs>
								</Card>
							</Stack>
						</ModalBody>
						<ModalFooter>
							<ButtonGroup spacing="5">
								<Button
									size="lg"
									borderRadius="full"
									colorScheme="red"
									onClick={() => {
										onBoardClose();
										setCurrMatchupId("");
										setEditMode(false);
									}}>
									Close
								</Button>
							</ButtonGroup>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};
