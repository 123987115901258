import React from "react";
import {
	Box,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Container,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useBreakpointValue,
	ChakraProvider,
	Image,
	Stack,
	AbsoluteCenter,
	HStack,
	Heading,
	Divider,
	VStack,
	Center,
	Circle,
} from "@chakra-ui/react";

import memberimg1 from "../../../images/member-img1.png";
import clubLogo from "../../../assets/clublogo.png";
import carromLogo from "../../../images/logo.png";
import { MdPersonPin } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";
import ApiService from "../../../service/Api.service";
import { playerImgDir } from "../../../Utilities/ImageLinks";
import { useAuthProvider } from "../../../context/AuthProvider";

export const PlayerMatchup = ({ matchupId, tournamentname, round }) => {
	// User Details
	const user = useAuthProvider();

	const [players, setPlayers] = useState(null);
	async function fetchPlayers() {
		try {
			let playersRes = await ApiService.httpGet(
				`/TourStandings?Class=PlayersInfo&MatchupId=${matchupId}`,
				user.userId
			);
			if (playersRes.status === true) {
				setPlayers({
					...playersRes.PlayersInfo[0],
					MatchType: playersRes.MatchType,
				});
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchPlayers();
	}, []);
	if (players) {
		return (
			<Flex
				userSelect={"none"}
				as="section"
				direction="column"
				height="100vh"
				bg="#4B39B7"
				px={3}
				py={3}
				gap={10}>
				<HStack justify={"space-between"}>
					{/* Club Image */}
					<Image
						src={clubLogo}
						alt="Club Image"
						blockSize={75}
						objectFit="contain"
						draggable="false"
						zIndex={1}
					/>
					<Heading
						size={"sm"}
						position={"absolute"}
						left={"50%"}
						transform={"translate(-50%)"}
						textAlign={"center"}
						textColor={"white"}
						fontWeight={"medium"}>
						{tournamentname}
					</Heading>
					{/* Carrom Logo */}
					<Image
						src={carromLogo}
						alt="Carrom Platform Logo"
						blockSize={100}
						objectFit="contain"
						draggable="false"
						zIndex={1}
					/>
				</HStack>
				<Stack spacing={10}>
					<Text
						fontSize={"xl"}
						textAlign={"center"}
						textColor={"white"}
						fontWeight={"medium"}
						textTransform={"uppercase"}>
						{round && `${round}`}
					</Text>

					<HStack justify="space-evenly">
						<Stack spacing={5}>
							<HStack spacing={5} justify={"center"} alignItems={"left"}>
								<Stack
									flex={"1"}
									spacing={3}
									direction="column"
									textAlign="left"
									align={"center"}>
									<Image
										src={`${playerImgDir}${players.Team1Player1ImageLink}`}
										alt="Player Image"
										draggable="false"
										objectFit="cover"
										boxSize={105}
										borderRadius={"full"}
										fallback={<FaUserCircle color="#90A4AE" size={105} />}
									/>
									<Text
										textAlign="center"
										fontSize={"lg"}
										textColor="#FFFFFF"
										fontWeight="bold"
										whiteSpace={"nowrap"}>
										{players.Team1Player1Name}
									</Text>
								</Stack>
								{players.MatchType === "Doubles" && (
									<Stack
										flex={"1"}
										spacing={3}
										direction="column"
										textAlign="left"
										align={"center"}>
										<Image
											src={`${playerImgDir}${players.Team1Player2ImageLink}`}
											alt="Player Image"
											draggable="false"
											objectFit="cover"
											boxSize={105}
											borderRadius={"full"}
											fallback={<FaUserCircle color="#90A4AE" size={105} />}
										/>
										<Text
											textAlign="center"
											fontSize={"lg"}
											textColor="#FFFFFF"
											fontWeight="bold">
											{players.Team1Player2Name}
										</Text>
									</Stack>
								)}
							</HStack>
							{players.MatchType !== "Singles" && (
								<Text
									textAlign="center"
									fontSize={"xl"}
									textColor="#FFFFFF"
									fontWeight="bold"
									whiteSpace={"nowrap"}>
									{players.Team1Name}
								</Text>
							)}
							<Table bg={"#4B39B7"} variant={"unstyled"}>
								<Thead>
									<Tr>
										<Th
											textColor="#FFFFFF"
											fontSize="md"
											textAlign="start"
											border={0}>
											Played
										</Th>
										<Th
											textColor="#FFFFFF"
											fontSize="md"
											textAlign="start"
											border={0}>
											Won
										</Th>
										<Th
											textColor="#FFFFFF"
											fontSize="md"
											textAlign="start"
											border={0}>
											Lost
										</Th>
										<Th
											textColor="#FFFFFF"
											fontSize="md"
											textAlign="start"
											border={0}>
											Points
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td
											textAlign="center"
											fontSize="lg"
											fontWeight="medium"
											textColor="#FFFFFF">
											{players.MatchType === "Doubles"
												? players.Team1Played
												: players.Team1Player1Played}
										</Td>
										<Td
											textAlign="center"
											fontSize="lg"
											fontWeight="medium"
											textColor="#FFFFFF">
											{players.MatchType === "Doubles"
												? players.Team1Won
												: players.Team1Player1Won}
										</Td>
										<Td
											textAlign="center"
											fontSize="lg"
											fontWeight="medium"
											textColor="#FFFFFF">
											{players.MatchType === "Doubles"
												? players.Team1Lost
												: players.Team1Player1Lost}
										</Td>
										<Td
											textAlign="center"
											fontSize="lg"
											fontWeight="medium"
											textColor="#FFFFFF">
											{players.MatchType === "Doubles"
												? players.Team1Points
												: players.Team1Player1Points}
										</Td>
									</Tr>
								</Tbody>
							</Table>
						</Stack>
						<Text textColor={"white"} fontSize={"2xl"}>
							vs
						</Text>
						<Stack spacing={5}>
							<HStack spacing={5} justify={"center"} alignItems={"left"}>
								<Stack
									flex={"1"}
									spacing={3}
									direction="column"
									textAlign="center"
									align={"center"}>
									<Circle size={105}>
										<Image
											src={`${playerImgDir}${players.Team2Player1ImageLink}`}
											alt="Player Image"
											draggable="false"
											objectFit="cover"
											boxSize={105}
											borderRadius={"full"}
											fallback={<FaUserCircle color="#90A4AE" size={105} />}
										/>
									</Circle>
									<Text
										textAlign="center"
										fontSize={"lg"}
										textColor="#FFFFFF"
										fontWeight="bold"
										whiteSpace={"nowrap"}>
										{players.Team2Player1Name}
									</Text>
								</Stack>
								{players.MatchType === "Doubles" && (
									<Stack
										flex={"1"}
										spacing={3}
										direction="column"
										textAlign="left"
										align={"center"}>
										<Image
											src={`${playerImgDir}${players.Team2Player2ImageLink}`}
											alt="Player Image"
											draggable="false"
											objectFit="cover"
											boxSize={105}
											borderRadius={"full"}
											fallback={<FaUserCircle color="#90A4AE" size={105} />}
										/>
										<Text
											textAlign="center"
											fontSize={"lg"}
											textColor="#FFFFFF"
											fontWeight="bold"
											whiteSpace={"nowrap"}>
											{players.Team2Player2Name}
										</Text>
									</Stack>
								)}
							</HStack>
							{players.MatchType !== "Singles" && (
								<Text
									textAlign="center"
									fontSize={"xl"}
									textColor="#FFFFFF"
									fontWeight="bold">
									{players.Team2Name}
								</Text>
							)}
							<Table bg={"#4B39B7"} variant={"unstyled"}>
								<Thead>
									<Tr>
										<Th
											textColor="#FFFFFF"
											fontSize="md"
											textAlign="start"
											border={0}>
											Played
										</Th>
										<Th
											textColor="#FFFFFF"
											fontSize="md"
											textAlign="start"
											border={0}>
											Won
										</Th>
										<Th
											textColor="#FFFFFF"
											fontSize="md"
											textAlign="start"
											border={0}>
											Lost
										</Th>
										<Th
											textColor="#FFFFFF"
											fontSize="md"
											textAlign="start"
											border={0}>
											Points
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td
											textAlign="center"
											fontSize="lg"
											fontWeight="medium"
											textColor="#FFFFFF">
											{players.MatchType === "Doubles"
												? players.Team2Played
												: players.Team2Player1Played}
										</Td>
										<Td
											textAlign="center"
											fontSize="lg"
											fontWeight="medium"
											textColor="#FFFFFF">
											{players.MatchType === "Doubles"
												? players.Team2Won
												: players.Team2Player1Won}
										</Td>
										<Td
											textAlign="center"
											fontSize="lg"
											fontWeight="medium"
											textColor="#FFFFFF">
											{players.MatchType === "Doubles"
												? players.Team2Lost
												: players.Team2Player1Lost}
										</Td>
										<Td
											textAlign="center"
											fontSize="lg"
											fontWeight="medium"
											textColor="#FFFFFF">
											{players.MatchType === "Doubles"
												? players.Team2Points
												: players.Team2Player1Points}
										</Td>
									</Tr>
								</Tbody>
							</Table>
						</Stack>
					</HStack>
				</Stack>
			</Flex>
		);
	} else {
		return null;
	}
};
