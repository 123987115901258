import {
	HStack,
	Image,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	VStack,
	FormControl,
	Select,
	Input,
	TableContainer,
	useBreakpointValue,
	Container,
	Text,
	Stack,
	Divider,
	useColorModeValue,
	FormLabel,
	IconButton,
	Icon,
	position,
	ButtonGroup,
	Button,
	useBoolean,
	Flex,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Heading,
	ModalFooter,
	useToast,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { TbActions, TbAdd, TbDelete } from "../../../icons/Icons";
import ApiService from "../../../service/Api.service";
import { data } from "./data";

export const BoardScoresTable = (props) => {
	let location = useLocation();
	// For Modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	let toast = useToast();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
		lg: false,
		xl: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	let TourId, EventId, MatchupId;
	[TourId, EventId, MatchupId] = location.pathname.split("/").slice(3);

	let boardsData = props.boardsData;
	console.log("boardsdata",boardsData);
console.log("player1score",props.boardsData.Player1TotalScore)
	let scoresData = props.scoresData;
	let [addBoard, setAddBoard] = useState([]);

	let [disableAddBtn, setDisableAddBtn] = useState(false);

	// For Actions Button
	let [editToggleId, setEditToggleId] = useState(null);

	const scrollToRef = useRef(null);

	useEffect(() => {
		// Need to run on initial render
		if (scoresData !== undefined) {
			setAddBoard(scoresData);
			console.log("initial data set", scrollToRef);
			scoresData[scoresData.length - 1]?.BoardScore === 0 &&
				props.boardsData.IsComplete === false &&
				props.boardsData.FirstStrike !== null &&
				!(
					props.boardsData.Team1SittingLeft === false &&
					props.boardsData.Team2SittingLeft === false
				) &&
				setEditToggleId(
					props.setCount > 1
						? Number(
								scoresData[scoresData.length - 1]?.BoardName.toString() +
									props.selectedSet.toString()
						  )
						: Number(scoresData[scoresData.length - 1]?.BoardName)
				);
			scoresData[scoresData.length - 1]?.BoardScore === 0 && setDisableAddBtn(true);
		}
	}, [
		scoresData,
		props.boardsData.FirstStrike,
		props.boardsData.Team1SittingLeft,
		props.boardsData.Team2SittingLeft,
	]);
	let addBoardButton = () => {
		let newBoard = {
			BoardName: `${addBoard.length + 1}`,
			Player1Score: 0,
			Player2Score: 0,
			Queen: "",
			BoardScore: 0,
			Winner: "",
		};

		setAddBoard([...addBoard, newBoard]);
		console.log("Added New Board...");
		setEditToggleId(
			props.setCount > 1
				? Number((addBoard.length + 1).toString() + props.selectedSet.toString())
				: Number(addBoard.length + 1)
		);
		setDisableAddBtn(true);
	};
	useEffect(() => {
		// To Scroll to the latest element
		scrollToRef.current && scrollToRef.current.scrollIntoView({ behavior: "smooth" });
	}, [addBoard, props.selectedSet]);
	console.log(editToggleId);
	// const saveQueen = async (board) => {
	// 	try {
	// 		props.setLoading(true);
	// 		let reqObj = {
	// 			MatchupId: props.matchupId,
	// 			Board: board.BoardName,
	// 			Queen: board.Queen,
	// 		};
	// 		let url = `/TourEventScores?Class=UpdateQueen`;
	// 		if (props.setCount > 1) {
	// 			url = `/TourEventScores?Class=UpdateQueen&KnockoutSet=${props.selectedSet}`;
	// 		}
	// 		let saveQueenRes = await ApiService.httpPut(url, reqObj);
	// 		if (saveQueenRes.status === true) {
	// 			toast({
	// 				title: "Save Queen!",
	// 				description: "Successfully Saved Queen",
	// 				status: "success",
	// 				duration: 3000,
	// 				isClosable: true,
	// 				position: "top-right",
	// 			});
	// 		} else {
	// 			toast({
	// 				title: "Save Queen!",
	// 				description: "Failed to Save Queen",
	// 				status: "warning",
	// 				duration: 3000,
	// 				isClosable: true,
	// 				position: "top-right",
	// 			});
	// 		}
	// 		props.setLoading(false);
	// 		props.refreshView(props.matchupId, props.selectedSet);
	// 	} catch (err) {
	// 		console.log(err);
	// 		toast({
	// 			title: "Save Queen!",
	// 			description: "Failed to Save Queen",
	// 			status: "warning",
	// 			duration: 3000,
	// 			isClosable: true,
	// 			position: "top-right",
	// 		});
	// 		props.setLoading(false);
	// 	}
	// };
	const saveBoard = async (board) => {
		if (board.Queen === "" || board.Queen === null || board.Queen === undefined) {
			toast({
				title: "Save Board!",
				description: "Please Select Queen",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} else {
			try {
				props.setLoading(true);
				let reqObj = {
					MatchupId: props.matchupId,
					Board: board.BoardName,
					Team1Score: Number(board.Player1Score),
					Team2Score: Number(board.Player2Score),
					Queen: board.Queen,
				};
				let url = `/TourEventScores?fetchScores=True`;
				if (props.setCount > 1) {
					url = `/TourEventScores?KnockoutSet=${props.selectedSet}`;
				}
				props?.setBSLoading && props?.setBSLoading(props.matchupId);
				let saveBoardRes = await ApiService.httpPost(url, reqObj);
				console.log("Saved Board Data:", saveBoardRes);
				if (saveBoardRes.status === true) {
					toast({
						title: "Save Board!",
						description: saveBoardRes.Message,
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					await props.refreshOnScoresSave(
						props.matchupId,
						props.selectedSet,
						saveBoardRes.boardScores
					);
					props.refreshMatchups !== undefined && props.refreshMatchups();
					setDisableAddBtn(false);
					setEditToggleId("");
					props?.setBSLoading && props?.setBSLoading(null);
				} else if (saveBoardRes.status === false && saveBoardRes.IsWarning === true) {
					toast({
						title: "Save Board!",
						description: saveBoardRes.Message,
						status: "warning",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					props.setLoading(false);
					props?.setBSLoading && props?.setBSLoading(null);
				} else {
					toast({
						title: "Save Board!",
						description: "Failed to Save Board Scores",
						status: "warning",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				props.setLoading(false);
				props?.setBSLoading && props?.setBSLoading(null);
			} catch (err) {
				console.log(err);
				toast({
					title: "Save Board!",
					description: "Failed to Save Board details",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.setLoading(false);
				props?.setBSLoading && props?.setBSLoading(null);
			}
		}
	};

	const completeMatch = async (e) => {
		try {
			let url = `/TourEventScores?MatchupId=${props.matchupId}`;
			if (props.setCount > 1) {
				url = `/TourEventScores?MatchupId=${props.matchupId}&KnockoutSet=${props.selectedSet}`;
			}
			let completeRes = await ApiService.httpPut(url);
			console.log("Completed Match:", completeRes);
			if (completeRes.status === true) {
				toast({
					title: props.setCount > 1 ? "Set Complete!" : "Match Complete!",
					description:
						props.setCount > 1
							? "Set Completed Successfully"
							: "Match Completed Successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: props.setCount > 1 ? "Set Complete" : "Match Complete",
					description: completeRes.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}

			props.refreshView(props.matchupId, props.selectedSet);
			onClose();
		} catch (err) {
			console.log(err);
			toast({
				title: props.setCount > 1 ? "Set Complete!" : "Match Complete!",
				description:
					props.setCount > 1 ? "Failed to Complete Set" : "Failed to Complete Match",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const tableDesktop = () => (
		<>
			<Box borderTopRadius={"2xl"} boxShadow={shadow}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th
								textColor="white"
								fontSize={"md"}
								textAlign={isTablet ? "end" : "center"}
								borderTopLeftRadius="2xl"
								px={3}
								py={4}
								{...(isMobile && {
									px: 1.5,
									maxW: "1rem",
									fontSize: "xs",
									py: 3,
								})}
								{...(isTablet && {
									maxW: "-webkit-fit-content",
									px: 1,
									fontSize: "sm",
								})}>
								{isMobile ? "#" : "Board"}
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}>
								Queen
							</Th>
							{isMobile ? (
								<Th
									textColor="white"
									fontSize={isTablet ? "sm" : "md"}
									textAlign="center"
									{...(isMobile && { px: 1, fontSize: "xs" })}
									{...(isTablet && { px: 2 })}
									whiteSpace={"break-spaces"}>
									{props.playersData[0]?.Name}
								</Th>
							) : (
								<Th
									textColor="white"
									fontSize={isTablet ? "sm" : "md"}
									textAlign="center"
									px={3}
									{...(isMobile && { px: 1.5, fontSize: "xs" })}
									{...(isTablet && { px: 2 })}>
									{props.playersData[0]?.Name}
								</Th>
							)}
							{isMobile ? (
								<Th
									textColor="white"
									fontSize={isTablet ? "sm" : "md"}
									textAlign="center"
									{...(isMobile && { px: 1, fontSize: "xs" })}
									{...(isTablet && { px: 2 })}
									whiteSpace={"break-spaces"}>
									{props.playersData[1]?.Name}
								</Th>
							) : (
								<Th
									textColor="white"
									fontSize={isTablet ? "sm" : "md"}
									textAlign="center"
									px={3}
									{...(isMobile && { px: 1, fontSize: "xs" })}
									{...(isTablet && { px: 2 })}>
									{props.playersData[1]?.Name}
								</Th>
							)}
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								textAlign="center"
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								{...(isTablet && { px: 2 })}
								whiteSpace={"break-spaces"}>
								Board Scores 
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								textAlign="center"
								px={3}
								borderTopRightRadius="2xl"
								{...(isMobile && { px: 1, fontSize: "xs" })}
								{...(isTablet && { px: 2 })}>
								Actions
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{addBoard?.map((board, index) => (
							<Tr key={index}>
								<Td
									ref={scrollToRef}
									textAlign={"center"}
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										maxW: "-webkit-fit-content",
										fontSize: "sm",
									})}
									{...(isTablet && {
										px: 2,
										maxW: "-webkit-fit-content",
									})}>
									{board.BoardName}
								</Td>
								<Td
									textAlign="start"
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										fontSize: "sm",
									})}
									{...(isTablet && {
										px: 2,
										fontSize: "md",
									})}
									whiteSpace={"normal"}>
									{editToggleId ===
									(props.setCount > 1 
										? Number(
												board.BoardName.toString() +
													props.selectedSet.toString()
										  )
										: Number(board.BoardName)) ? (
										<FormControl
											textAlign="start"
											w={"-webkit-fit-content"}
											{...(isTablet && { px: 1 })}>
											<Select
												defaultValue={board.Queen}
												placeholder="Select Player Name"
												onChange={(e) => {
													board.Queen = e.target.value;
													// saveQueen(board);
												}}
												disabled={
													((editToggleId !==
														(props.setCount > 1
															? Number(
																board.BoardName.toString() +
																props.selectedSet.toString()
															)
															: Number(board.BoardName))) || props.loading)
														? true
														: false
												}
												{...(isMobile && {
													fontSize: "xs",
													sx: { px: "1" },
												})}
												{...(isTablet && {
													fontSize: "sm",
													sx: { px: "2" },
												})}>
												{props.playersData.map((player, index) => {
													return (
														<option key={index} value={player.Id}>
															{player.Name}
														</option>
													);
												})}
											</Select>
										</FormControl>
									) : (
										board.QueenName
									)}
								</Td>
								<Td
									textAlign="-webkit-center"
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										fontSize: "sm",
									})}
									{...(isTablet && {
										px: 2,
										fontSize: "md",
									})}>
									{editToggleId ===
									(props.setCount > 1
										? Number(
												board.BoardName.toString() +
													props.selectedSet.toString()
										  )
										: Number(board.BoardName)) ? (
										<FormControl w="fit-content">
											<NumberInput
												defaultValue={board.Player1Score}
												min={0}
												max={9}
												inputMode="numeric"
												keepWithinRange={true}
												onChange={(value) => (board.Player1Score = value)}
												disabled={
													((editToggleId !==
														(props.setCount > 1
															? Number(
																board.BoardName.toString() +
																props.selectedSet.toString()
															)
															: Number(board.BoardName))) || props.loading)
														? true
														: false
												}>
												<NumberInputField
													fontSize={
														isMobile ? "sm" : isTablet ? "md" : "lg"
													}
													textAlign={"center"}
													padding={0}
													disabled={props.loading}
												/>
											</NumberInput>
										</FormControl>
									) : (
										board.Player1Score
									)}
								</Td>
								<Td
									textAlign="-webkit-center"
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										fontSize: "sm",
									})}
									{...(isTablet && {
										px: 2,
										fontSize: "md",
									})}>
									{editToggleId ===
									(props.setCount > 1
										? Number(
												board.BoardName.toString() +
													props.selectedSet.toString()
										  )
										: Number(board.BoardName)) ? (
										<FormControl w="fit-content">
											<NumberInput
												defaultValue={board.Player2Score}
												min={0}
												max={9}
												inputMode="numeric"
												keepWithinRange={true}
												onChange={(value) => (board.Player2Score = value)}
												disabled={
													((editToggleId !==
														(props.setCount > 1
															? Number(
																board.BoardName.toString() +
																props.selectedSet.toString()
															)
															: Number(board.BoardName))) || props.loading)
														? true
														: false
												}>
												<NumberInputField
													fontSize={
														isMobile ? "sm" : isTablet ? "md" : "lg"
													}
													textAlign={"center"}
													padding={0}
													disabled={props.loading}
												/>
											</NumberInput>
										</FormControl>
									) : (
										board.Player2Score
									)}
								</Td>
								<Td
									textAlign="center"
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										fontSize: "sm",
									})}
									{...(isTablet && {
										px: 2,
										fontSize: "md",
									})}>
									{board.BoardScore}
								</Td>

								<Td {...(isMobile && { px: 0 })} {...(isTablet && { px: 2 })}>
									{boardsData &&
										editToggleId !==
											(props.setCount > 1
												? Number(
														board.BoardName.toString() +
															props.selectedSet.toString()
												  )
												: Number(board.BoardName)) && (
											<Flex justify={"center"} p="0">
												<IconButton
													icon={<TbActions />}
													{...(isMobile && { size: "sm" })}
													mb={1}
													onClick={() => {
														setEditToggleId(
															props.setCount > 1
																? Number(
																		board.BoardName.toString() +
																			props.selectedSet.toString()
																  )
																: Number(board.BoardName)
														);
														setDisableAddBtn(true);
														console.log(board);
													}}
													disabled={
														props.boardsData.IsComplete === true 
														//disableAddBtn 
														// props.boardsData?.FirstStrike === null
													}
												/>
											</Flex>
										)}

									{editToggleId &&
										editToggleId ===
											(props.setCount > 1
												? Number(
														board.BoardName.toString() +
															props.selectedSet.toString()
												  )
												: Number(board.BoardName)) && (
											<HStack justify="center" spacing={1}>
												<IconButton
													borderRadius="full"
													bgColor="#43CC18"
													{...(isMobile && { size: "xs" })}
													{...(isTablet && { size: "sm" })}
													_hover={{ bgColor: "green" }}
													icon={<TbAdd />}
													onClick={(e) => saveBoard(board)}
													disabled={props.boardsData.IsComplete === true}
													isLoading={props.loading}
												/>
												{!props.loading && (
													<IconButton
														borderRadius="full"
														disabled={
															props.boardsData.IsComplete === true ||
															("BoardId" in board &&
																Number(board.Player1Score) === 0 &&
																Number(board.Player2Score) === 0)
														}
														onClick={() => {
															if ("BoardId" in board) {
																if (
																	Number(board.Player1Score) >
																		0 ||
																	Number(board.Player2Score) > 0
																) {
																	setEditToggleId("");
																	setDisableAddBtn(false);
																}
															} else {
																addBoard.splice(index, 1);
																setEditToggleId("");
																setDisableAddBtn(false);
															}
														}}
														bgColor="#FF4667"
														{...(isMobile && { size: "xs" })}
														{...(isTablet && { size: "sm" })}
														_hover={{ bgColor: "red" }}
														_active={{ bgColor: "red" }}
														icon={<TbDelete />}
													/>
												)}
											</HStack>
										)}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<Stack
				direction={"row"}
				justify="space-between"
				p="1rem"
				bgColor="white"
				borderBottomRadius={"2xl"}>
				<Button
					color="white"
					bgColor="#43CC18"
					borderRadius="full"
					{...(isMobile && { size: "sm" })}
					onClick={onOpen}
					disabled={props.boardsData.IsComplete === true}>
					{props.setCount > 1 ? "Set Complete" : "Match Complete"}
				</Button>
				<Button
					color="bg-accent"
					bgColor="#bee3f8"
					borderRadius="full"
					{...(isMobile && { size: "sm" })}
					onClick={addBoardButton}
					isLoading={props.loading}
					disabled={
						 props.boardsData.IsComplete === true ||
						 disableAddBtn  ||
						 props.boardsData.Player1TotalScore>=25 ||
						 props.boardsData.Player2TotalScore>=25
						//props.boardsData?.FirstStrike === null
					}>
					+ Add New Board
				</Button>
			</Stack>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{props.setCount > 1 ? "Confirm Set Completion" : "Confirm Match Completion"}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure want to submit?</Text>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={completeMatch}>
							Submit
						</Button>
						<Button onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);

	/* const tableMobile = () => (
    <Container
      bgColor="white"
      boxShadow={"sm"}
      borderRadius="lg"
      py="1rem"
      px="1.5rem"
    >
      <Stack spacing={5} divider={<Divider />} fontSize="lg">
        <HStack justify="space-between">
          <Text fontWeight="bold">Board</Text>
          <Text fontWeight="bold">{data[0].board}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">Board Score</Text>
          <Text fontWeight="bold">{data[0].boardscore}</Text>
        </HStack>
        <HStack justify="space-between">
          <FormControl>
            <FormLabel fontWeight="bold" textColor="black">
              Queen
            </FormLabel>
            <Select>
              {data[0].queen.map((queen, key) => {
                return <option key={key}>{queen}</option>;
              })}
            </Select>
          </FormControl>
        </HStack>
        <HStack justify="space-between">
          <FormControl>
            <FormLabel fontWeight="bold" textColor="black">
              {data[0].queen[0]}
            </FormLabel>
            <Input placeholder={data[0].player1} bgColor="gray.100" />
          </FormControl>
        </HStack>
        <HStack justify="space-between">
          <FormControl>
            <FormLabel fontWeight="bold" textColor="black">
              {data[0].queen[1]}
            </FormLabel>
            <Input placeholder={data[0].player2} bgColor="gray.100" />
          </FormControl>
        </HStack>
        <HStack justify="center">
          <IconButton
            borderRadius="full"
            bgColor="green"
            _hover={{ bgColor: "green" }}
            icon={<TbAdd />}
          />
          <IconButton
            borderRadius="full"
            bgColor="red"
            _hover={{ bgColor: "red" }}
            icon={<TbDelete />}
          />
        </HStack>
        <Stack direction={["column", "row"]} justify="space-between" pt="1rem">
          <Button color="#4E4EE9" bgColor="#E8E3FF" borderRadius="full">
            + Add New Board
          </Button>
          <Button color="white" bgColor="#4E4EE9" borderRadius="full">
            Match Complete
          </Button>
        </Stack>
      </Stack>
    </Container>
  ); */

	return tableDesktop();
};
